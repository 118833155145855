import React, { ReactElement, useEffect } from 'react'
import './trustpilot.style.less'

const TRUST_PILOT_BANNER_HEIGHT = 24

export const TrustPilotBanner = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="64193ee14d729569d0f95b84"
      data-style-height={`${TRUST_PILOT_BANNER_HEIGHT}px`}
      data-style-width="100%"
      data-theme="dark"
      data-min-review-count="5"
      data-style-alignment="center"
      style={{ backgroundColor: '#1c1c1c' }}
    >
      <a href="https://fr.trustpilot.com/review/maposaic.com" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  )
}

const TrustPilotWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <div className="trustpilot-wrapper">
      <TrustPilotBanner />
      {children}
    </div>
  )
}
export default TrustPilotWrapper
