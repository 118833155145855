import React, { lazy, Suspense } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Spin } from 'antd'
import spinner from 'assets/spinner.png'
import 'spinner.style.less'
import 'App.style.less'
import { useCheckWasmAvailability } from 'Maposaic/utils'
import 'firebaseService/initialize'
import TermsAndConditions from 'TermsAndConditions/TermsAndConditions'
import TrustPilotWrapper from 'TrustPilot/TrustPilotWrapper'

const Gallery = lazy(() => import('Gallery/Gallery'))
const Maposaic = lazy(() => import('Maposaic/Maposaic'))
const GameOfLife = lazy(() => import('GameOfLife/GameOfLife'))
const HomePage = lazy(() => import('HomePage/HomePage'))

const App = () => {
  const { isWasmAvailable } = useCheckWasmAvailability()

  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            spinning={true}
            className="fallback-spinner"
            indicator={<img className="spinner" src={spinner} alt="spin" />}
          />
        }
      >
        <Switch>
          <Route path="/gallery">
            <TrustPilotWrapper>
              <Gallery />
            </TrustPilotWrapper>
          </Route>
          <Route path="/game-of-life">
            <GameOfLife />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/create">
            <Maposaic isWasmAvailable={isWasmAvailable} />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
