export const colorHunt = [
  ['#6a2c70', '#b83b5e', '#f08a5d', '#f9ed69'],
  ['#eeeeee', '#00adb5', '#393e46', '#222831'],
  ['#95e1d3', '#eaffd0', '#fce38a', '#f38181'],
  ['#eaeaea', '#ff2e63', '#252a34', '#08d9d6'],
  ['#fc5185', '#f5f5f5', '#3fc1c9', '#364f6b'],
  ['#ffffd2', '#fcbad3', '#aa96da', '#a8d8ea'],
  ['#71c9ce', '#a6e3e9', '#cbf1f5', '#e3fdfd'],
  ['#40514e', '#11999e', '#30e3ca', '#e4f9f5'],
  ['#8785a2', '#f6f6f6', '#ffe2e2', '#ffc7c7'],
  ['#112d4e', '#3f72af', '#dbe2ef', '#f9f7f7'],
  ['#61c0bf', '#bbded6', '#fae3d9', '#ffb6b9'],
  ['#53354a', '#903749', '#e84545', '#2b2e4a'],
  ['#ffde7d', '#f6416c', '#f8f3d4', '#00b8a9'],
  ['#abedd8', '#46cdcf', '#3d84a8', '#48466d'],
  ['#311d3f', '#522546', '#88304e', '#e23e57'],
  ['#a5dee5', '#e0f9b5', '#fefdca', '#ffcfdf'],
  ['#14ffec', '#0d7377', '#323232', '#212121'],
  ['#ffaaa5', '#ffd3b6', '#dcedc1', '#a8e6cf'],
  ['#cca8e9', '#c3bef0', '#cadefc', '#defcf9'],
  ['#355c7d', '#6c5b7b', '#c06c84', '#f67280'],
  ['#3e4149', '#444f5a', '#ff9999', '#ffc8c8'],
  ['#ff165d', '#ff9a00', '#f6f7d7', '#3ec1d3'],
  ['#ffd460', '#f07b3f', '#ea5455', '#2d4059'],
  ['#99ddcc', '#f6f6f6', '#ffe2e2', '#bad7df'],
  ['#521262', '#6639a6', '#3490de', '#6fe7dd'],
  ['#edb1f1', '#d59bf6', '#9896f1', '#8ef6e4'],
  ['#cabbe9', '#ffcef3', '#fdfdfd', '#a1eafb'],
  ['#ff5722', '#eeeeee', '#00adb5', '#303841'],
  ['#1e2022', '#52616b', '#c9d6df', '#f0f5f9'],
  ['#c4edde', '#7ac7c4', '#f73859', '#384259'],
  ['#ff8c94', '#ffaaa6', '#ffd3b5', '#dcedc2'],
  ['#283c63', '#928a97', '#fbe8d3', '#f85f73'],
  ['#ff7e67', '#fafafa', '#a2d5f2', '#07689f'],
  ['#769fcd', '#b9d7ea', '#d6e6f2', '#f7fbfc'],
  ['#fecea8', '#ff847c', '#e84a5f', '#2a363b'],
  ['#ffaaa5', '#ffd3b6', '#fdffab', '#a8e6cf'],
  ['#e8ffe8', '#a6fff2', '#74f9ff', '#00e0ff'],
  ['#a6d0e4', '#f9ffea', '#ffecda', '#d4a5a5'],
  ['#ff9de2', '#8c82fc', '#b693fe', '#7effdb'],
  ['#f57170', '#f5f5f5', '#10ddc2', '#15b7b9'],
  ['#d7fbe8', '#9df3c4', '#62d2a2', '#1fab89'],
  ['#625772', '#f9a1bc', '#fefaec', '#a9eee6'],
  ['#a56cc1', '#a6acec', '#ace7ef', '#cefff1'],
  ['#0dceda', '#6ef3d6', '#c6fce5', '#ebfffa'],
  ['#878ecd', '#b9bbdf', '#dde7f2', '#dff4f3'],
  ['#f25d9c', '#b61aae', '#590d82', '#0c056d'],
  ['#fbafaf', '#f2c6b4', '#f3e8cb', '#99e1e5'],
  ['#eeeeee', '#d72323', '#3a4750', '#303841'],
  ['#004a7c', '#005691', '#e8f1f5', '#fafafa'],
  ['#ffc93c', '#ff9a3c', '#ff6f3c', '#155263'],
  ['#fc85ae', '#9e579d', '#574b90', '#303a52'],
  ['#c86b85', '#e6a4b4', '#f3d7ca', '#f5eee6'],
  ['#dbedf3', '#f73859', '#404b69', '#283149'],
  ['#ed8d8d', '#8d6262', '#4d4545', '#393232'],
  ['#aedefc', '#fff6f6', '#ffdfdf', '#fb929e'],
  ['#fa4659', '#f0fff3', '#c6f1e7', '#11cbd7'],
  ['#7c7575', '#b8b0b0', '#dfd3d3', '#fbf0f0'],
  ['#7e6bc4', '#c79ecf', '#d6c8ff', '#fef0ff'],
  ['#fff5a5', '#ffaa64', '#ff8264', '#ff6464'],
  ['#c5e3f6', '#fc5c9c', '#fccde2', '#fcefee'],
  ['#625772', '#f38181', '#fefaec', '#a9eee6'],
  ['#f76b8a', '#fcfefe', '#eaf6f6', '#66bfbf'],
  ['#f5c7f7', '#a393eb', '#5e63b6', '#27296d'],
  ['#6c5b7c', '#c06c84', '#f67280', '#f8b595'],
  ['#d9faff', '#00bbf0', '#005792', '#00204a'],
  ['#7098da', '#6eb6ff', '#90f2ff', '#e0fcff'],
  ['#e3e3e3', '#f95959', '#455d7a', '#233142'],
  ['#ffbd39', '#e61c5d', '#930077', '#3a0088'],
  ['#537780', '#11d3bc', '#55e9bc', '#fffcca'],
  ['#a7ff83', '#17b978', '#086972', '#071a52'],
  ['#f67280', '#c06c84', '#6c5b7b', '#35477d'],
  ['#163172', '#1e56a0', '#d6e4f0', '#f6f6f6'],
  ['#f2f4c3', '#ffdcf5', '#fdc7ff', '#c7f3ff'],
  ['#d2ecf9', '#1891ac', '#1f5f8b', '#253b6e'],
  ['#f5f5f5', '#d3d4d8', '#3fbac2', '#4d606e'],
  ['#bfcfff', '#c8e7ed', '#ffffc2', '#ffa5a5'],
  ['#eeeeee', '#4ecca3', '#393e46', '#232931'],
  ['#e67a7a', '#ffebb7', '#fff4e1', '#9ddcdc'],
  ['#537791', '#c1c0b9', '#f7f6e7', '#e7e6e1'],
  ['#f3f798', '#eab4f8', '#fcc8f8', '#c7f5fe'],
  ['#8f8787', '#ebcbae', '#f9f9f9', '#a6e4e7'],
  ['#649dad', '#66c6ba', '#a4e5d9', '#c8f4de'],
  ['#fbac91', '#fbe1b6', '#7fdfd4', '#a7efe9'],
  ['#4a266a', '#7f4a88', '#de95ba', '#ffd9e8'],
  ['#fecea8', '#ff847b', '#e84a5f', '#2a363b'],
  ['#8971d0', '#7dace4', '#95e8d7', '#adf7d1'],
  ['#2f9296', '#46b7b9', '#87dfd6', '#dff5f2'],
  ['#ff7c38', '#e03e36', '#b80d57', '#700961'],
  ['#427996', '#645c84', '#a2738c', '#eaafaf'],
  ['#1f4e5f', '#79a8a9', '#aacfd0', '#f4f7f7'],
  ['#685454', '#ea8a8a', '#ebd5d5', '#f2f2f2'],
  ['#00fff5', '#00adb5', '#393e46', '#222831'],
  ['#355c7d', '#c06c84', '#f67280', '#f8b195'],
  ['#f2bbbb', '#ed93cb', '#ca82f8', '#a1d9ff'],
  ['#756c83', '#f38181', '#b9e1dc', '#fbfbfb'],
  ['#c0ffc2', '#fdffba', '#ffeab6', '#f69d9d'],
  ['#70a1d7', '#a1de93', '#f7f48b', '#f47c7c'],
  ['#ecf2f9', '#c8d9eb', '#f0d9da', '#f9ecec'],
  ['#403f48', '#596c68', '#95a792', '#e3d9ca'],
  ['#2eb872', '#a3de83', '#feffe4', '#fa4659'],
  ['#ba52ed', '#ff99fe', '#a4f6f9', '#e4fffe'],
  ['#f7e9e3', '#ffc0c2', '#6dc9c8', '#0e3150'],
  ['#80d6ff', '#edf798', '#fab57a', '#f06868'],
  ['#f1c40f', '#34495e', '#ecf0f1', '#3498db'],
  ['#d988bc', '#ffa8b8', '#ffd2a5', '#ffffc1'],
  ['#34495e', '#5da0a2', '#aacfd0', '#f4f7f7'],
  ['#ff8260', '#ff4057', '#900048', '#240041'],
  ['#1a3c40', '#144d53', '#307672', '#e4eddb'],
  ['#29252c', '#33425b', '#d8e9f0', '#f33535'],
  ['#f8ecfd', '#c264fe', '#a82ffc', '#7a08fa'],
  ['#ea0599', '#9a0f98', '#6a0572', '#39065a'],
  ['#048998', '#3bb4c1', '#e3e3e3', '#f6f5f5'],
  ['#4797b1', '#c5ecbe', '#f7f3ce', '#ffdede'],
  ['#ffc300', '#ec610a', '#a40a3c', '#6b0848'],
  ['#eeeeee', '#00adb5', '#3a4750', '#303841'],
  ['#f6c7c7', '#fd94b4', '#ff467e', '#f12b6b'],
  ['#616161', '#8aae92', '#c4e3cb', '#f4f9f4'],
  ['#ffcee4', '#fb90b7', '#d01257', '#0f1021'],
  ['#bbe1fa', '#3282b8', '#0f4c75', '#1b262c'],
  ['#474744', '#2994b2', '#fffbe0', '#ffb400'],
  ['#163a5f', '#1d566e', '#21aba5', '#45eba5'],
  ['#3d6cb9', '#00d1ff', '#00fff0', '#fafaf6'],
  ['#1d2786', '#6a65d8', '#ffcdcd', '#ffe6eb'],
  ['#f19584', '#fea386', '#f6e4c4', '#29c6cd'],
  ['#ffc4d0', '#f7ddde', '#fbe8e7', '#fcf5ee'],
  ['#00c9b1', '#005d6c', '#00043c', '#ffa3ac'],
  ['#56132a', '#741938', '#c61951', '#f64662'],
  ['#eafff7', '#afffdf', '#49beb7', '#fc345c'],
  ['#00eaff', '#1f8ea3', '#284184', '#020438'],
  ['#0245a3', '#8fbaf3', '#bdf1f6', '#f2fcfc'],
  ['#264e86', '#0074e4', '#74dbef', '#eff0f4'],
  ['#ffe8d5', '#fdb87d', '#ff8364', '#ff4d4d'],
  ['#a3f7bf', '#29a19c', '#435055', '#27323a'],
  ['#596e79', '#c7b198', '#dfd3c3', '#f0ece2'],
  ['#ac73ff', '#aca8ff', '#9de5ff', '#d5fdff'],
  ['#cffdf8', '#65c0ba', '#216583', '#f76262'],
  ['#dbedf3', '#00818a', '#404b69', '#283149'],
  ['#8a1253', '#c51350', '#e8751a', '#fda403'],
  ['#470031', '#971549', '#cf455c', '#ff9898'],
  ['#59606d', '#70acb1', '#c6f1e7', '#f0fff3'],
  ['#bb5a5a', '#e79e85', '#eaceb4', '#f2e9d0'],
  ['#e7e7de', '#008891', '#00587a', '#0f3057'],
  ['#6e5773', '#d45d79', '#ea9085', '#e9e2d0'],
  ['#fe9797', '#dd5b82', '#913175', '#560764'],
  ['#f6fcae', '#d89fff', '#c7f5ff', '#ffabe5'],
  ['#7c73e6', '#c4c1e0', '#ffe9e3', '#fafafa'],
  ['#020205', '#e43a19', '#f2f4f7', '#111f4d'],
  ['#ffd6d6', '#fca7a7', '#fc624d', '#18587a'],
  ['#7e80ff', '#ebc6ff', '#fbeeff', '#c7ffff'],
  ['#f56262', '#fca180', '#ffd480', '#fffe9f'],
  ['#34495e', '#22313f', '#8dc6ff', '#e4f1fe'],
  ['#f8b195', '#f67280', '#c06c84', '#6c567b'],
  ['#3090a1', '#7bcecc', '#fef8e6', '#bc5148'],
  ['#ccffec', '#ffdede', '#ffe8cf', '#fdfdc4'],
  ['#a6aa9c', '#f7d3ba', '#e6e7e5', '#f5efe3'],
  ['#454553', '#4aa0d5', '#d8e9f0', '#eb586f'],
  ['#fff8b5', '#b5ff7d', '#52d681', '#00ad7c'],
  ['#3d0240', '#137083', '#b7b7b7', '#fae3e3'],
  ['#d7f8f7', '#bee4d2', '#fab2ac', '#eda1c1'],
  ['#fcd2c2', '#ffbbbb', '#0b3846', '#294a66'],
  ['#6892d5', '#79d1c3', '#c9fdd7', '#f8fcfb'],
  ['#1a0841', '#4f9da6', '#ffad5a', '#ff5959'],
  ['#476269', '#40a798', '#f1f1f1', '#f5e1da'],
  ['#3b4a6b', '#22b2da', '#f0d43a', '#f23557'],
  ['#ea5455', '#f6f6f6', '#ffb400', '#2d4059'],
  ['#8a79af', '#d38cad', '#ffd2a5', '#ffffc1'],
  ['#e3eff3', '#cde3eb', '#6e828a', '#143a52'],
  ['#eeeeee', '#0092ca', '#393e46', '#222831'],
  ['#fcb2bf', '#cf56a1', '#8b2f97', '#511e78'],
  ['#6c737e', '#7393a7', '#b5cfd8', '#e8ecf1'],
  ['#ff89c0', '#e3ffc3', '#6df1cc', '#2fc5cc'],
  ['#fde9c9', '#fcc29a', '#13829b', '#29d2e4'],
  ['#95e1d3', '#d6f7ad', '#fce38a', '#f38181'],
  ['#ff8364', '#ffb677', '#ffd98e', '#6bd5e1'],
  ['#efd510', '#f2910a', '#e94822', '#2c2d34'],
  ['#5c8d89', '#74b49b', '#a7d7c5', '#d3f6d1'],
  ['#ffcab0', '#ffebbb', '#fdffcd', '#e0ffcd'],
  ['#5a92af', '#86c1d4', '#9cd9de', '#d9f9f4'],
  ['#eeeeee', '#f6c90e', '#3a4750', '#303841'],
  ['#e8d5b7', '#f9b248', '#fc3a52', '#0e2431'],
  ['#141010', '#680747', '#c3195d', '#f70776'],
  ['#957dad', '#d291bc', '#fec8d8', '#ffdfd3'],
  ['#ffe084', '#ffc057', '#f98b60', '#ea5959'],
  ['#fde9c9', '#ea5455', '#2d4059', '#343434'],
  ['#ff5733', '#c70039', '#900c3f', '#581845'],
  ['#21e6c1', '#278ea5', '#1f4287', '#071e3d'],
  ['#ffffdf', '#3a718c', '#5ea3a6', '#f0bebe'],
  ['#f6f6f6', '#ee2b47', '#2c2e3e', '#34374c'],
  ['#f8b500', '#5c636e', '#393e46', '#f7f7f7'],
  ['#f60c86', '#fff6a2', '#9feed1', '#11cbd7'],
  ['#476268', '#40a798', '#f1f1f1', '#f5e1da'],
  ['#f7f7f7', '#93deff', '#606470', '#323643'],
  ['#fd5f00', '#f6f6e9', '#005792', '#13334c'],
  ['#ff5126', '#fcedda', '#b6d7de', '#daebee'],
  ['#00334e', '#145374', '#5588a3', '#e8e8e8'],
  ['#353940', '#3e92a3', '#dfe0d4', '#ff5335'],
  ['#9efcb4', '#f5fc9e', '#fddd8a', '#fd9191'],
  ['#1f4e5f', '#0881a3', '#f4e7d3', '#f9f8ed'],
  ['#27253d', '#326765', '#7da87b', '#f5f5c6'],
  ['#f9c4aa', '#fee4a6', '#d2e1c8', '#f7f4e3'],
]
