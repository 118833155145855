export const coolors = [
  ['#247ba0', '#70c1b3', '#b2dbbf', '#f3ffbd', '#ff1654'],
  ['#05668d', '#028090', '#00a896', '#02c39a', '#f0f3bd'],
  ['#ffcdb2', '#ffb4a2', '#e5989b', '#b5838d', '#6d6875'],
  ['#e63946', '#f1faee', '#a8dadc', '#457b9d', '#1d3557'],
  ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
  ['#1a535c', '#4ecdc4', '#f7fff7', '#ff6b6b', '#ffe66d'],
  ['#50514f', '#f25f5c', '#ffe066', '#247ba0', '#70c1b3'],
  ['#2b2d42', '#8d99ae', '#edf2f4', '#ef233c', '#d90429'],
  ['#d8e2dc', '#ffe5d9', '#ffcad4', '#f4acb7', '#9d8189'],
  ['#ffffff', '#00171f', '#003459', '#007ea7', '#00a8e8'],
  ['#fe938c', '#e6b89c', '#ead2ac', '#9cafb7', '#4281a4'],
  ['#003049', '#d62828', '#f77f00', '#fcbf49', '#eae2b7'],
  ['#ef476f', '#ffd166', '#06d6a0', '#118ab2', '#073b4c'],
  ['#5bc0eb', '#fde74c', '#9bc53d', '#e55934', '#fa7921'],
  ['#ed6a5a', '#f4f1bb', '#9bc1bc', '#5ca4a9', '#e6ebe0'],
  ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#6fffe9'],
  ['#f2d7ee', '#d3bcc0', '#a5668b', '#69306d', '#0e103d'],
  ['#9c89b8', '#f0a6ca', '#efc3e6', '#f0e6ef', '#b8bedd'],
  ['#000000', '#14213d', '#fca311', '#e5e5e5', '#ffffff'],
  ['#bce784', '#5dd39e', '#348aa7', '#525174', '#513b56'],
  ['#22223b', '#4a4e69', '#9a8c98', '#c9ada7', '#f2e9e4'],
  ['#114b5f', '#028090', '#e4fde1', '#456990', '#f45b69'],
  ['#ff9f1c', '#ffbf69', '#ffffff', '#cbf3f0', '#2ec4b6'],
  ['#3d5a80', '#98c1d9', '#e0fbfc', '#ee6c4d', '#293241'],
  ['#114b5f', '#1a936f', '#88d498', '#c6dabf', '#f3e9d2'],
  ['#dcdcdd', '#c5c3c6', '#46494c', '#4c5c68', '#1985a1'],
  ['#7bdff2', '#b2f7ef', '#eff7f6', '#f7d6e0', '#f2b5d4'],
  ['#2d3142', '#4f5d75', '#bfc0c0', '#ffffff', '#ef8354'],
  ['#011627', '#f71735', '#41ead4', '#fdfffc', '#ff9f1c'],
  ['#06aed5', '#086788', '#f0c808', '#fff1d0', '#dd1c1a'],
  ['#f6511d', '#ffb400', '#00a6ed', '#7fb800', '#0d2c54'],
  ['#d8dbe2', '#a9bcd0', '#58a4b0', '#373f51', '#1b1b1e'],
  ['#083d77', '#ebebd3', '#f4d35e', '#ee964b', '#f95738'],
  ['#ffb997', '#f67e7d', '#843b62', '#0b032d', '#74546a'],
  ['#cfdbd5', '#e8eddf', '#f5cb5c', '#242423', '#333533'],
  ['#13293d', '#006494', '#247ba0', '#1b98e0', '#e8f1f2'],
  ['#3d315b', '#444b6e', '#708b75', '#9ab87a', '#f8f991'],
  ['#05668d', '#427aa1', '#ebf2fa', '#679436', '#a5be00'],
  ['#c9cba3', '#ffe1a8', '#e26d5c', '#723d46', '#472d30'],
  ['#20bf55', '#0b4f6c', '#01baef', '#fbfbff', '#757575'],
  ['#ffa69e', '#faf3dd', '#b8f2e6', '#aed9e0', '#5e6472'],
  ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69'],
  ['#292f36', '#4ecdc4', '#f7fff7', '#ff6b6b', '#ffe66d'],
  ['#6699cc', '#fff275', '#ff8c42', '#ff3c38', '#a23e48'],
  ['#ffbe0b', '#fb5607', '#ff006e', '#8338ec', '#3a86ff'],
  ['#220901', '#621708', '#941b0c', '#bc3908', '#f6aa1c'],
  ['#d3f8e2', '#e4c1f9', '#f694c1', '#ede7b1', '#a9def9'],
  ['#fbfbf2', '#e5e6e4', '#cfd2cd', '#a6a2a2', '#847577'],
  ['#ffbf00', '#e83f6f', '#2274a5', '#32936f', '#ffffff'],
  ['#353535', '#3c6e71', '#ffffff', '#d9d9d9', '#284b63'],
  ['#1be7ff', '#6eeb83', '#e4ff1a', '#e8aa14', '#ff5714'],
  ['#1b998b', '#2d3047', '#fffd82', '#ff9b71', '#e84855'],
  ['#b8d8ba', '#d9dbbc', '#fcddbc', '#ef959d', '#69585f'],
  ['#f8ffe5', '#06d6a0', '#1b9aaa', '#ef476f', '#ffc43d'],
  ['#f0b67f', '#fe5f55', '#d6d1b1', '#c7efcf', '#eef5db'],
  ['#2d3142', '#bfc0c0', '#ffffff', '#ef8354', '#4f5d75'],
  ['#ffac81', '#ff928b', '#fec3a6', '#efe9ae', '#cdeac0'],
  ['#f4f1de', '#e07a5f', '#3d405b', '#81b29a', '#f2cc8f'],
  ['#cad2c5', '#84a98c', '#52796f', '#354f52', '#2f3e46'],
  ['#ff5e5b', '#d8d8d8', '#ffffea', '#00cecb', '#ffed66'],
  ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#ffffff'],
  ['#ecf8f8', '#eee4e1', '#e7d8c9', '#e6beae', '#b2967d'],
  ['#2e5266', '#6e8898', '#9fb1bc', '#d3d0cb', '#e2c044'],
  ['#f7b267', '#f79d65', '#f4845f', '#f27059', '#f25c54'],
  ['#faf3dd', '#c8d5b9', '#8fc0a9', '#68b0ab', '#4a7c59'],
  ['#231942', '#5e548e', '#9f86c0', '#be95c4', '#e0b1cb'],
  ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fffcf9'],
  ['#03256c', '#2541b2', '#1768ac', '#06bee1', '#ffffff'],
  ['#555b6e', '#89b0ae', '#bee3db', '#faf9f9', '#ffd6ba'],
  ['#335c67', '#fff3b0', '#e09f3e', '#9e2a2b', '#540b0e'],
  ['#000000', '#ffffff', '#494949', '#7c7a7a', '#ff5d73'],
  ['#64a6bd', '#90a8c3', '#ada7c9', '#d7b9d5', '#f4cae0'],
  ['#b8d8d8', '#7a9e9f', '#4f6367', '#eef5db', '#fe5f55'],
  ['#26547c', '#ef476f', '#ffd166', '#06d6a0', '#fcfcfc'],
  ['#ffe74c', '#ff5964', '#ffffff', '#6bf178', '#35a7ff'],
  ['#07beb8', '#3dccc7', '#68d8d6', '#9ceaef', '#c4fff9'],
  ['#0c0f0a', '#ff206e', '#fbff12', '#41ead4', '#ffffff'],
  ['#4f000b', '#720026', '#ce4257', '#ff7f51', '#ff9b54'],
  ['#4c5454', '#ff715b', '#ffffff', '#1ea896', '#523f38'],
  ['#2b2d42', '#8d99ae', '#edf2f4', '#ef233c', '#d80032'],
  ['#033f63', '#28666e', '#7c9885', '#b5b682', '#fedc97'],
  ['#283d3b', '#197278', '#edddd4', '#c44536', '#772e25'],
  ['#495867', '#577399', '#bdd5ea', '#f7f7ff', '#fe5f55'],
  ['#25ced1', '#ffffff', '#fceade', '#ff8a5b', '#ea526f'],
  ['#edeec9', '#dde7c7', '#bfd8bd', '#98c9a3', '#77bfa3'],
  ['#000000', '#2f4550', '#586f7c', '#b8dbd9', '#f4f4f9'],
  ['#fe4a49', '#2ab7ca', '#fed766', '#e6e6ea', '#f4f4f8'],
  ['#a9e5bb', '#fcf6b1', '#f7b32b', '#f72c25', '#2d1e2f'],
  ['#565264', '#706677', '#a6808c', '#ccb7ae', '#d6cfcb'],
  ['#3c1642', '#086375', '#1dd3b0', '#affc41', '#b2ff9e'],
  ['#52489c', '#4062bb', '#59c3c3', '#ebebeb', '#f45b69'],
  ['#5d737e', '#64b6ac', '#c0fdfb', '#daffef', '#fcfffd'],
  ['#36213e', '#554971', '#63768d', '#8ac6d0', '#b8f3ff'],
  ['#0d1321', '#1d2d44', '#3e5c76', '#748cab', '#f0ebd8'],
  ['#c9e4ca', '#87bba2', '#55828b', '#3b6064', '#364958'],
  ['#fe5f55', '#f0b67f', '#d6d1b1', '#c7efcf', '#eef5db'],
  ['#bfc3ba', '#a9aca9', '#60495a', '#3f3244', '#2f2235'],
  ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93'],
  ['#1a1423', '#372549', '#774c60', '#b75d69', '#eacdc2'],
  ['#b0d0d3', '#c08497', '#f7af9d', '#f7e3af', '#f3eec3'],
  ['#773344', '#e3b5a4', '#f5e9e2', '#0b0014', '#d44d5c'],
  ['#fffcf2', '#ccc5b9', '#403d39', '#252422', '#eb5e28'],
  ['#0fa3b1', '#d9e5d6', '#eddea4', '#f7a072', '#ff9b42'],
  ['#f9dbbd', '#ffa5ab', '#da627d', '#a53860', '#450920'],
  ['#002626', '#0e4749', '#95c623', '#e55812', '#efe7da'],
  ['#ef6461', '#e4b363', '#e8e9eb', '#e0dfd5', '#313638'],
  ['#ff4e00', '#8ea604', '#f5bb00', '#ec9f05', '#bf3100'],
  ['#d7263d', '#f46036', '#2e294e', '#1b998b', '#c5d86d'],
  ['#0d1b2a', '#1b263b', '#415a77', '#778da9', '#e0e1dd'],
  ['#cc5803', '#e2711d', '#ff9505', '#ffb627', '#ffc971'],
  ['#084c61', '#db504a', '#e3b505', '#4f6d7a', '#56a3a6'],
  ['#d5c5c8', '#9da3a4', '#604d53', '#db7f8e', '#ffdbda'],
  ['#006ba6', '#0496ff', '#ffbc42', '#d81159', '#8f2d56'],
  ['#cae7b9', '#f3de8a', '#eb9486', '#7e7f9a', '#97a7b3'],
  ['#e09f7d', '#ef5d60', '#ec4067', '#a01a7d', '#311847'],
  ['#ff6700', '#ebebeb', '#c0c0c0', '#3a6ea5', '#004e98'],
  ['#e1ce7a', '#fbffb9', '#fdd692', '#ec7357', '#754f44'],
  ['#f7c59f', '#2a324b', '#767b91', '#c7ccdb', '#e1e5ee'],
  ['#484a47', '#5c6d70', '#a37774', '#e88873', '#e0ac9d'],
  ['#0c090d', '#e01a4f', '#f15946', '#f9c22e', '#53b3cb'],
  ['#463f3a', '#8a817c', '#bcb8b1', '#f4f3ee', '#e0afa0'],
  ['#11151c', '#212d40', '#364156', '#7d4e57', '#d66853'],
  ['#233d4d', '#fe7f2d', '#fcca46', '#a1c181', '#619b8a'],
  ['#177e89', '#084c61', '#db3a34', '#ffc857', '#323031'],
  ['#fdc5f5', '#f7aef8', '#b388eb', '#8093f1', '#72ddf7'],
  ['#ffc09f', '#ffee93', '#fcf5c7', '#a0ced9', '#adf7b6'],
  ['#3a2e39', '#1e555c', '#f4d8cd', '#edb183', '#f15152'],
  ['#588b8b', '#ffffff', '#ffd5c2', '#f28f3b', '#c8553d'],
  ['#0a1128', '#001f54', '#034078', '#1282a2', '#fefcfb'],
  ['#ddd8c4', '#a3c9a8', '#84b59f', '#69a297', '#50808e'],
  ['#db504a', '#ff6f59', '#254441', '#43aa8b', '#b2b09b'],
  ['#320a28', '#511730', '#8e443d', '#cb9173', '#e0d68a'],
  ['#0d3b66', '#faf0ca', '#f4d35e', '#ee964b', '#f95738'],
  ['#dd6e42', '#e8dab2', '#4f6d7a', '#c0d6df', '#eaeaea'],
  ['#102542', '#f87060', '#cdd7d6', '#b3a394', '#ffffff'],
  ['#ff99c8', '#fcf6bd', '#d0f4de', '#a9def9', '#e4c1f9'],
  ['#ffffff', '#81f4e1', '#56cbf9', '#ff729f', '#d3c4d1'],
  ['#eca400', '#eaf8bf', '#006992', '#27476e', '#001d4a'],
  ['#211103', '#3d1308', '#7b0d1e', '#9f2042', '#f8e5ee'],
  ['#040f0f', '#248232', '#2ba84a', '#2d3a3a', '#fcfffc'],
  ['#5f0f40', '#9a031e', '#fb8b24', '#e36414', '#0f4c5c'],
  ['#f9c80e', '#f86624', '#ea3546', '#662e9b', '#43bccd'],
  ['#0c0a3e', '#7b1e7a', '#b33f62', '#f9564f', '#f3c677'],
  ['#390099', '#9e0059', '#ff0054', '#ff5400', '#ffbd00'],
  ['#f79256', '#fbd1a2', '#7dcfb6', '#00b2ca', '#1d4e89'],
  ['#202c39', '#283845', '#b8b08d', '#f2d492', '#f29559'],
  ['#050505', '#1b9aaa', '#dddbcb', '#f5f1e3', '#ffffff'],
  ['#ffa69e', '#ff7e6b', '#8c5e58', '#a9f0d1', '#fff7f8'],
  ['#ffe74c', '#ff5964', '#ffffff', '#38618c', '#35a7ff'],
  ['#272727', '#fed766', '#009fb7', '#696773', '#eff1f3'],
  ['#01161e', '#124559', '#598392', '#aec3b0', '#eff6e0'],
  ['#2f4858', '#33658a', '#86bbd8', '#f6ae2d', '#f26419'],
  ['#080705', '#40434e', '#702632', '#912f40', '#fffffa'],
  ['#086788', '#07a0c3', '#f0c808', '#fff1d0', '#dd1c1a'],
  ['#edae49', '#d1495b', '#00798c', '#30638e', '#003d5b'],
  ['#efc7c2', '#ffe5d4', '#bfd3c1', '#68a691', '#694f5d'],
  ['#1f2041', '#4b3f72', '#ffc857', '#119da4', '#19647e'],
  ['#ddfff7', '#93e1d8', '#ffa69e', '#aa4465', '#462255'],
  ['#efd9ce', '#dec0f1', '#b79ced', '#957fef', '#7161ef'],
  ['#e54b4b', '#ffa987', '#f7ebe8', '#444140', '#1e1e24'],
  ['#0d0630', '#18314f', '#384e77', '#8bbeb2', '#e6f9af'],
  ['#f7d1cd', '#e8c2ca', '#d1b3c4', '#b392ac', '#735d78'],
  ['#97f9f9', '#a4def9', '#c1e0f7', '#cfbae1', '#c59fc9'],
  ['#0a2463', '#3e92cc', '#fffaff', '#d8315b', '#1e1b18'],
  ['#725752', '#878e88', '#96c0b7', '#d4dfc7', '#fef6c9'],
  ['#8e9aaf', '#cbc0d3', '#efd3d7', '#feeafa', '#dee2ff'],
  ['#250902', '#38040e', '#640d14', '#800e13', '#ad2831'],
  ['#042a2b', '#5eb1bf', '#cdedf6', '#ef7b45', '#d84727'],
  ['#533a71', '#6184d8', '#50c5b7', '#9cec5b', '#f0f465'],
  ['#ebd4cb', '#da9f93', '#b6465f', '#890620', '#2c0703'],
  ['#ccdad1', '#9caea9', '#788585', '#6f6866', '#38302e'],
  ['#666a86', '#788aa3', '#92b6b1', '#b2c9ab', '#e8ddb5'],
  ['#cc444b', '#da5552', '#df7373', '#e39695', '#e4b1ab'],
  ['#55dde0', '#33658a', '#2f4858', '#f6ae2d', '#f26419'],
  ['#424b54', '#b38d97', '#d5aca9', '#ebcfb2', '#c5baaf'],
  ['#f7f4ea', '#ded9e2', '#c0b9dd', '#80a1d4', '#75c9c8'],
  ['#ee6055', '#60d394', '#aaf683', '#ffd97d', '#ff9b85'],
  ['#586ba4', '#324376', '#f5dd90', '#f68e5f', '#f76c5e'],
  ['#faa275', '#ff8c61', '#ce6a85', '#985277', '#5c374c'],
  ['#461220', '#8c2f39', '#b23a48', '#fcb9b2', '#fed0bb'],
  ['#c33c54', '#254e70', '#37718e', '#8ee3ef', '#aef3e7'],
  ['#0b3954', '#087e8b', '#bfd7ea', '#ff5a5f', '#c81d25'],
  ['#4d9de0', '#e15554', '#e1bc29', '#3bb273', '#7768ae'],
  ['#6b717e', '#efaac4', '#ffc4d1', '#ffe8e1', '#d4dccd'],
  ['#ffaf87', '#ff8e72', '#ed6a5e', '#4ce0b3', '#377771'],
  ['#dbc2cf', '#9fa2b2', '#3c7a89', '#2e4756', '#16262e'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#292f36', '#4ecdc4', '#ffffff', '#ff6b6b'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#292f36', '#4ecdc4', '#ffffff', '#ff6b6b'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#292f36', '#4ecdc4', '#ffffff', '#ff6b6b'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#292f36', '#4ecdc4', '#ffffff', '#ff6b6b'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#031d44', '#04395e', '#70a288', '#dab785', '#d5896f'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#83b692', '#f9ada0', '#f9627d', '#c65b7c', '#5b3758'],
  ['#292f36', '#4ecdc4', '#ffffff', '#ff6b6b'],
  ['#160f29', '#246a73', '#368f8b', '#f3dfc1', '#ddbea8'],
  ['#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894'],
  ['#9a8f97', '#c3baba', '#e9e3e6', '#b2b2b2', '#736f72'],
  ['#9f7e69', '#d2bba0', '#f2efc7', '#f7ffe0', '#ffeee2'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de'],
  ['#533747', '#5f506b', '#6a6b83', '#76949f', '#86bbbd'],
  ['#70d6ff', '#ff70a6', '#ff9770', '#ffd670', '#e9ff70'],
  ['#baf2bb', '#baf2d8', '#bad7f2', '#f2bac9', '#f2e2ba'],
  ['#e5f9e0', '#a3f7b5', '#40c9a2', '#2f9c95', '#664147'],
  ['#01295f', '#437f97', '#849324', '#ffb30f', '#fd151b'],
  ['#5c9ead', '#ffffff', '#326273', '#eeeeee', '#e39774'],
  ['#00a6fb', '#0582ca', '#006494', '#003554', '#051923'],
  ['#3f7cac', '#95afba', '#bdc4a7', '#d5e1a3', '#e2f89c'],
  ['#000000', '#0c1821', '#1b2a41', '#324a5f', '#ccc9dc'],
  ['#f79f79', '#f7d08a', '#e3f09b', '#87b6a7', '#5b5941'],
  ['#ff6978', '#fffcf9', '#b1ede8', '#6d435a', '#352d39'],
  ['#4281a4', '#48a9a6', '#e4dfda', '#d4b483', '#c1666b'],
  ['#000505', '#3b3355', '#5d5d81', '#bfcde0', '#fefcfd'],
  ['#bee9e8', '#62b6cb', '#1b4965', '#cae9ff', '#5fa8d3'],
  ['#931621', '#28464b', '#326771', '#2c8c99', '#42d9c8'],
  ['#a4243b', '#d8c99b', '#d8973c', '#bd632f', '#273e47'],
  ['#ee6352', '#59cd90', '#3fa7d6', '#fac05e', '#f79d84'],
  ['#0a0908', '#22333b', '#eae0d5', '#c6ac8f', '#5e503f'],
  ['#f6f7eb', '#e94f37', '#393e41', '#3f88c5', '#44bba4'],
  ['#dad2d8', '#143642', '#0f8b8d', '#ec9a29', '#a8201a'],
  ['#0fa3b1', '#b5e2fa', '#f9f7f3', '#eddea4', '#f7a072'],
  ['#ffb8d1', '#e4b4c2', '#e7cee3', '#e0e1e9', '#ddfdfe'],
  ['#231f20', '#bb4430', '#7ebdc2', '#f3dfa2', '#efe6dd'],
  ['#134074', '#13315c', '#0b2545', '#8da9c4', '#eef4ed'],
  ['#424b54', '#93a8ac', '#ffffff', '#e2b4bd', '#9b6a6c'],
  ['#3772ff', '#f038ff', '#ef709d', '#e2ef70', '#70e4ef'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#cebebe', '#ece2d0', '#d5b9b2', '#a26769', '#6d2e46'],
  ['#0c120c', '#c20114', '#6d7275', '#c7d6d5', '#ecebf3'],
  ['#e9d758', '#297373', '#ff8552', '#e6e6e6', '#39393a'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#6b9080', '#a4c3b2', '#cce3de', '#eaf4f4', '#f6fff8'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#031926', '#468189', '#77aca2', '#9dbebb', '#f4e9cd'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#f1dac4', '#a69cac', '#474973', '#161b33', '#0d0c1d'],
  ['#63474d', '#aa767c', '#d6a184', '#ffa686', '#fec196'],
  ['#000000', '#839788', '#eee0cb', '#baa898', '#bfd7ea'],
  ['#000000', '#cf5c36', '#eee5e9', '#7c7c7c', '#efc88b'],
  ['#F04358', '#FFD262', '#05D5AA', '#107FB1', '#07364C'],
  ['#5D0D6E', '#ED4353', '#FED440', '#3CCDB4', '#0FAC71'],
]
