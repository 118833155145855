import { useEffect, useState } from 'react'
import screenfull from 'screenfull'

import { DEFAULT_MAP_TITLE } from 'Maposaic/constants'
import { FrameType } from 'Checkout/types'
import { SizeRef } from 'constants/dimensions'

export const isWasmSuported = async () => {
  try {
    const wasm = await import('map-converter')
    if (wasm.say_wan() === 1) {
      return true
    } else {
      return false
    }
  } catch {
    return false
  }
}

export const useCheckWasmAvailability = () => {
  const [isWasmAvailable, setIsWasmAvailable] = useState<boolean | null>(null)

  const checkWasmAvailability = async () => {
    if (await isWasmSuported()) {
      // eslint-disable-next-line no-console
      console.log('wasm available')
      setIsWasmAvailable(true)
    } else {
      // eslint-disable-next-line no-console
      console.log('wasm not available')
      setIsWasmAvailable(false)
    }
  }

  useEffect(() => {
    void checkWasmAvailability()
    // eslint-disable-next-line
  }, [])

  return { isWasmAvailable: !!isWasmAvailable }
}

export const isMobileWindow = () => window.innerWidth <= 799 // 🫣 value also in App.style.less

export const useCheckMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(isMobileWindow())
  const onSetSize = () => setIsMobile(isMobileWindow())
  useEffect(() => {
    onSetSize()
    window.addEventListener('resize', onSetSize)

    return () => {
      window.removeEventListener('resize', onSetSize)
    }
  }, [])

  return { isMobile }
}

export const useIsFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(!!document.fullscreenElement)

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        //eslint-disable-next-line
        // @ts-ignore
        setIsFullScreen(screenfull.isFullscreen)
      })
    }
  }, [])

  return { isFullScreen }
}

export const toggleFullScreen = async (isFullScreen: boolean, setDrawerVisible: (visible: boolean) => void) => {
  if (screenfull.isEnabled) {
    if (isFullScreen) {
      await screenfull.exit()
    } else {
      await screenfull.request()
      setDrawerVisible(false)
    }
  }
}

export const computeFrameStyle = ({
  frame,
  format,
  isPosterModeOn,
  isThumbnail = false,
}: {
  frame: FrameType | null
  format: SizeRef
  isPosterModeOn: boolean
  isThumbnail?: boolean
}) => {
  if (!frame || !isPosterModeOn) {
    return {}
  }
  let backgroundColor
  if (frame === FrameType.black) {
    backgroundColor = '#191919'
  } else if (frame === FrameType.natural) {
    backgroundColor = 'linear-gradient(to bottom, #C19A6B 0%, #B28B5C 100%)'
  } else if (frame === FrameType.white) {
    backgroundColor = 'white'
  }
  let padding = !isThumbnail ? '10px' : '5px'
  if (format === SizeRef.size11x14) {
    padding = !isThumbnail ? '14px' : '7px'
  } else if (format === SizeRef.size18x24) {
    padding = !isThumbnail ? '10px' : '5px'
  } else if (format === SizeRef.size24x32) {
    padding = !isThumbnail ? '8px' : '4px'
  }

  return {
    background: backgroundColor,
    padding,
  }
}

export const computePictureName = ({ placeName, mapTitle }: { placeName: string | null; mapTitle: string | null }) => {
  return mapTitle !== null && mapTitle !== DEFAULT_MAP_TITLE && mapTitle !== '' ? mapTitle : placeName ?? null
}
