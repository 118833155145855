export enum FrameType {
  black = 'black',
  natural = 'natural',
  white = 'white',
}
export type Quote = { amount: number; currency: string; country: SupportedCountry }
export enum CheckoutResultValue {
  success = 'success',
  failure = 'failure',
}
export const supportedCurrencies = ['AUD', 'EUR', 'GBP', 'USD']
export enum SupportedCountry {
  AF = 'AF',
  AL = 'AL',
  DZ = 'DZ',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BR = 'BR',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CO = 'CO',
  KM = 'KM',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  OM = 'OM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GB = 'GB',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  CI = 'CI',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KR = 'KR',
  XK = 'XK',
  KW = 'KW',
  KG = 'KG',
  KY = 'KY',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  CN = 'CN',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  MK = 'MK',
  NO = 'NO',
  PK = 'PK',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  CG = 'CG',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  ES = 'ES',
  LK = 'LK',
  SR = 'SR',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  TW = 'TW',
  TJ = 'TJ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  TZ = 'TZ',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}
export const countryLabels: { [key in SupportedCountry]: string } = {
  [SupportedCountry.AF]: 'Afghanistan',
  [SupportedCountry.AL]: 'Albania',
  [SupportedCountry.DZ]: 'Algeria',
  [SupportedCountry.AD]: 'Andorra',
  [SupportedCountry.AO]: 'Angola',
  [SupportedCountry.AI]: 'Anguilla',
  [SupportedCountry.AG]: 'Antigua and Barbuda',
  [SupportedCountry.AR]: 'Argentina',
  [SupportedCountry.AM]: 'Armenia',
  [SupportedCountry.AW]: 'Aruba',
  [SupportedCountry.AU]: 'Australia',
  [SupportedCountry.AT]: 'Austria',
  [SupportedCountry.AZ]: 'Azerbaijan',
  [SupportedCountry.BS]: 'Bahamas',
  [SupportedCountry.BH]: 'Bahrain',
  [SupportedCountry.BD]: 'Bangladesh',
  [SupportedCountry.BB]: 'Barbados',
  [SupportedCountry.BY]: 'Belarus',
  [SupportedCountry.BE]: 'Belgum',
  [SupportedCountry.BZ]: 'Belize',
  [SupportedCountry.BJ]: 'Benin',
  [SupportedCountry.BM]: 'Bermuda',
  [SupportedCountry.BT]: 'Bhutan',
  [SupportedCountry.BO]: 'Bolivia',
  [SupportedCountry.BA]: 'Bosnia and Herzegovina',
  [SupportedCountry.BW]: 'Botswana',
  [SupportedCountry.BR]: 'Brazil',
  [SupportedCountry.BN]: 'Brunei Darussalam',
  [SupportedCountry.BG]: 'Bulgaria',
  [SupportedCountry.BF]: 'Burkina Faso',
  [SupportedCountry.BI]: 'Burundi',
  [SupportedCountry.KH]: 'Cambodia',
  [SupportedCountry.CM]: 'Cameroon',
  [SupportedCountry.CA]: 'Canada',
  [SupportedCountry.CV]: 'Cape Verde',
  [SupportedCountry.CF]: 'Central African Republic',
  [SupportedCountry.TD]: 'Chad',
  [SupportedCountry.CL]: 'Chile',
  [SupportedCountry.CO]: 'Colombia',
  [SupportedCountry.KM]: 'Comoras',
  [SupportedCountry.CK]: 'Cook Islands',
  [SupportedCountry.CR]: 'Costa Rica',
  [SupportedCountry.HR]: 'Croatia',
  [SupportedCountry.CY]: 'Cyprus',
  [SupportedCountry.CZ]: 'Czech Republic',
  [SupportedCountry.DK]: 'Denmark',
  [SupportedCountry.DJ]: 'Djibouti',
  [SupportedCountry.DM]: 'Dominica',
  [SupportedCountry.OM]: 'Dominica',
  [SupportedCountry.DO]: 'Dominican Republic',
  [SupportedCountry.EC]: 'Ecuador',
  [SupportedCountry.EG]: 'Egypt',
  [SupportedCountry.SV]: 'El Salvador',
  [SupportedCountry.GQ]: 'Equatorial Guinea',
  [SupportedCountry.ER]: 'Eritrea',
  [SupportedCountry.EE]: 'Estonia',
  [SupportedCountry.ET]: 'Ethiopia',
  [SupportedCountry.FK]: 'Falkland Islands',
  [SupportedCountry.FO]: 'Faroe Islands',
  [SupportedCountry.FJ]: 'Fiji',
  [SupportedCountry.FI]: 'Finland',
  [SupportedCountry.FR]: 'France',
  [SupportedCountry.GF]: 'French Guiana',
  [SupportedCountry.PF]: 'French Polynesia',
  [SupportedCountry.GA]: 'Gabon',
  [SupportedCountry.GM]: 'Gambia',
  [SupportedCountry.GE]: 'Georgia',
  [SupportedCountry.DE]: 'Germany',
  [SupportedCountry.GH]: 'Ghana',
  [SupportedCountry.GI]: 'Gibraltar',
  [SupportedCountry.GB]: 'Great Britan',
  [SupportedCountry.GR]: 'Greece',
  [SupportedCountry.GL]: 'Greenland',
  [SupportedCountry.GD]: 'Grenada',
  [SupportedCountry.GP]: 'Guadeloupe',
  [SupportedCountry.GU]: 'Guam',
  [SupportedCountry.GT]: 'Guatemala',
  [SupportedCountry.GG]: 'Guernsey',
  [SupportedCountry.GN]: 'Guinea',
  [SupportedCountry.GW]: 'Guinea-Bissau',
  [SupportedCountry.GY]: 'Guyana',
  [SupportedCountry.HT]: 'Haiti',
  [SupportedCountry.HN]: 'Honduras',
  [SupportedCountry.HK]: 'Hong Kong SAR',
  [SupportedCountry.HU]: 'Hungary',
  [SupportedCountry.IS]: 'Iceland',
  [SupportedCountry.IN]: 'India',
  [SupportedCountry.ID]: 'Indonesia',
  [SupportedCountry.IQ]: 'Iraq',
  [SupportedCountry.IE]: 'Ireland',
  [SupportedCountry.IM]: 'Isle of Man',
  [SupportedCountry.IL]: 'Israel',
  [SupportedCountry.IT]: 'Italy',
  [SupportedCountry.CI]: 'Ivory Coast',
  [SupportedCountry.JM]: 'Jamaica',
  [SupportedCountry.JP]: 'Japan',
  [SupportedCountry.JE]: 'Jersey',
  [SupportedCountry.JO]: 'Jordan',
  [SupportedCountry.KZ]: 'Kazakhstan',
  [SupportedCountry.KE]: 'Kenya',
  [SupportedCountry.KI]: 'Kiribati',
  [SupportedCountry.KR]: 'Korea',
  [SupportedCountry.XK]: 'Kosovo',
  [SupportedCountry.KW]: 'Kuwait',
  [SupportedCountry.KG]: 'Kyrgyzstan',
  [SupportedCountry.KY]: 'Kyrgyzstan',
  [SupportedCountry.LA]: 'Laos',
  [SupportedCountry.LV]: 'Latvia',
  [SupportedCountry.LB]: 'Lebanon',
  [SupportedCountry.LS]: 'Lesotho',
  [SupportedCountry.LR]: 'Liberia',
  [SupportedCountry.LY]: 'Libya',
  [SupportedCountry.LI]: 'Liechtenstein',
  [SupportedCountry.LT]: 'Lithuania',
  [SupportedCountry.LU]: 'Luxembourg',
  [SupportedCountry.MO]: 'Macao SAR',
  [SupportedCountry.MG]: 'Madagascar',
  [SupportedCountry.CN]: 'Mainland China',
  [SupportedCountry.MW]: 'Malawi',
  [SupportedCountry.MY]: 'Malaysia',
  [SupportedCountry.MV]: 'Maldives',
  [SupportedCountry.ML]: 'Mali',
  [SupportedCountry.MT]: 'Malta',
  [SupportedCountry.MQ]: 'Martinique',
  [SupportedCountry.MR]: 'Mauritania',
  [SupportedCountry.MU]: 'Mauritius',
  [SupportedCountry.YT]: 'Mayotte',
  [SupportedCountry.MX]: 'Mexico',
  [SupportedCountry.MD]: 'Moldova',
  [SupportedCountry.MC]: 'Monaco',
  [SupportedCountry.MN]: 'Mongolia',
  [SupportedCountry.ME]: 'Montenegro',
  [SupportedCountry.MS]: 'Montserrat',
  [SupportedCountry.MA]: 'Morocco',
  [SupportedCountry.MZ]: 'Mozambique',
  [SupportedCountry.MM]: 'Myanmar',
  [SupportedCountry.NA]: 'Namibia',
  [SupportedCountry.NR]: 'Nauru',
  [SupportedCountry.NP]: 'Nepal',
  [SupportedCountry.NL]: 'Netherland',
  [SupportedCountry.NC]: 'New Caledonia',
  [SupportedCountry.NZ]: 'New Zealand',
  [SupportedCountry.NI]: 'Nicaragua',
  [SupportedCountry.NE]: 'Niger',
  [SupportedCountry.NG]: 'Nigeria',
  [SupportedCountry.NU]: 'Niue',
  [SupportedCountry.MK]: 'North Macedonia',
  [SupportedCountry.NO]: 'Norway',
  [SupportedCountry.PK]: 'Pakistan',
  [SupportedCountry.PS]: 'Palestinian Territories',
  [SupportedCountry.PA]: 'Panama',
  [SupportedCountry.PG]: 'Papua New Guinea',
  [SupportedCountry.PY]: 'Paraguay',
  [SupportedCountry.PE]: 'Peru',
  [SupportedCountry.PH]: 'Phillipeans',
  [SupportedCountry.PL]: 'Poland',
  [SupportedCountry.PT]: 'Portugal',
  [SupportedCountry.PR]: 'Puerto Rico',
  [SupportedCountry.QA]: 'Qatar',
  [SupportedCountry.CG]: 'Republic of the Congo',
  [SupportedCountry.RE]: 'Reunion',
  [SupportedCountry.RO]: 'Romania',
  [SupportedCountry.RU]: 'Russian Federation',
  [SupportedCountry.RW]: 'Rwanda',
  [SupportedCountry.SH]: 'Saint Helena',
  [SupportedCountry.KN]: 'Saint Kitts and Nevis',
  [SupportedCountry.LC]: 'Saint Lucia',
  [SupportedCountry.VC]: 'Saint Vincent and the Grenadines',
  [SupportedCountry.WS]: 'Samoa',
  [SupportedCountry.SM]: 'San Marino',
  [SupportedCountry.ST]: 'São Tomé and Príncipe',
  [SupportedCountry.SA]: 'Saudi Arabia',
  [SupportedCountry.SN]: 'Senegal',
  [SupportedCountry.RS]: 'Serbia',
  [SupportedCountry.SC]: 'Seychelles',
  [SupportedCountry.SL]: 'Sierra Leone',
  [SupportedCountry.SG]: 'Singapore',
  [SupportedCountry.SX]: 'Sint Maarten',
  [SupportedCountry.SK]: 'Slovakia',
  [SupportedCountry.SI]: 'Slovenia',
  [SupportedCountry.SB]: 'Solomon Islands',
  [SupportedCountry.SO]: 'Somalia',
  [SupportedCountry.ZA]: 'South Africa',
  [SupportedCountry.ES]: 'Spain',
  [SupportedCountry.LK]: 'Sri Lanka',
  [SupportedCountry.SR]: 'Suriname',
  [SupportedCountry.SZ]: 'Swaziland',
  [SupportedCountry.SE]: 'Sweden',
  [SupportedCountry.CH]: 'Switzerland',
  [SupportedCountry.TW]: 'Taiwan region',
  [SupportedCountry.TJ]: 'Tajikistan',
  [SupportedCountry.TH]: 'Thailand',
  [SupportedCountry.TL]: 'Timor-Leste',
  [SupportedCountry.TG]: 'Togo',
  [SupportedCountry.TO]: 'Tonga',
  [SupportedCountry.TT]: 'Trinidad and Tobago',
  [SupportedCountry.TN]: 'Tunisia',
  [SupportedCountry.TR]: 'Turkey',
  [SupportedCountry.TM]: 'Turkmenistan',
  [SupportedCountry.TC]: 'Turks and Caicos Islands',
  [SupportedCountry.TV]: 'Tuvalu',
  [SupportedCountry.UG]: 'Uganda',
  [SupportedCountry.UA]: 'Ukraine',
  [SupportedCountry.AE]: 'United Arab Emirates',
  [SupportedCountry.TZ]: 'United Republic of Tanzania',
  [SupportedCountry.US]: 'United States',
  [SupportedCountry.UY]: 'Uruguay',
  [SupportedCountry.UZ]: 'Uzbekistan',
  [SupportedCountry.VA]: 'Vatican City',
  [SupportedCountry.VE]: 'Venezuela',
  [SupportedCountry.VN]: 'Viet Nam',
  [SupportedCountry.VG]: 'Virgin Islands, British',
  [SupportedCountry.YE]: 'Yemen',
  [SupportedCountry.ZM]: 'Zambia',
  [SupportedCountry.ZW]: 'Zimbabwe',
}
