export const CM_PER_INCH = 2.54
export const FORMAT_RATIO = 29.7 / 21

export enum SizeRef {
  size11x14 = '11x14',
  size18x24 = '18x24',
  size24x32 = '24x32',
}
export enum SizeDisplay {
  size11x14inch = '11x14"',
  size18x24inch = '18x24"',
  size24x32inch = '24x32"',
  size28x35cm = '28x35cm',
  size45x60cm = '45x60cm',
  size60x80cm = '60x80cm',
}
export enum LengthUnit {
  inch = 'inch',
  cm = 'cm',
}

export const FORMAT_SIZE = {
  [SizeRef.size11x14]: { shortestLength: 27.94, longestLength: 35.56 },
  [SizeRef.size18x24]: { shortestLength: 45.72, longestLength: 60.96 },
  [SizeRef.size24x32]: { shortestLength: 60.96, longestLength: 81.28 },
}

export const FORMATS = [SizeRef.size11x14, SizeRef.size18x24, SizeRef.size24x32]
export const FORMAT_CHOICES: { [key in LengthUnit]: { text: string; options: { [ki in SizeRef]: SizeDisplay } } } = {
  [LengthUnit.inch]: {
    text: 'inches',
    options: {
      [SizeRef.size11x14]: SizeDisplay.size11x14inch,
      [SizeRef.size18x24]: SizeDisplay.size18x24inch,
      [SizeRef.size24x32]: SizeDisplay.size24x32inch,
    },
  },
  [LengthUnit.cm]: {
    text: 'centimeters',
    options: {
      [SizeRef.size11x14]: SizeDisplay.size28x35cm,
      [SizeRef.size18x24]: SizeDisplay.size45x60cm,
      [SizeRef.size24x32]: SizeDisplay.size60x80cm,
    },
  },
}
export const SIZE_DISPLAY_TO_REF: { [key in SizeDisplay]: { ref: SizeRef; unit: LengthUnit } } = {
  [SizeDisplay.size11x14inch]: { ref: SizeRef.size11x14, unit: LengthUnit.inch },
  [SizeDisplay.size18x24inch]: { ref: SizeRef.size18x24, unit: LengthUnit.inch },
  [SizeDisplay.size24x32inch]: { ref: SizeRef.size24x32, unit: LengthUnit.inch },
  [SizeDisplay.size28x35cm]: { ref: SizeRef.size11x14, unit: LengthUnit.cm },
  [SizeDisplay.size45x60cm]: { ref: SizeRef.size18x24, unit: LengthUnit.cm },
  [SizeDisplay.size60x80cm]: { ref: SizeRef.size24x32, unit: LengthUnit.cm },
}
