import { ROAD_WHITE } from 'Colors/constants'
import { ROAD_SIMPLE_WHITE, WATER_CYAN } from 'Colors/mapbox'
import { MapboxStyle } from 'Maposaic/types'

export const MAPBOX_STYLES = {
  [MapboxStyle.Details]: {
    url: 'mapbox://styles/cartapuce/clea58fgt003x01lcwa3pk3ep',
    name: 'Details',
    imgPath: '/assets/background_samples/map_relief.png',
  },
  [MapboxStyle.Road]: {
    url: 'mapbox://styles/cartapuce/ck8vk01zo2e5w1ipmytroxgf4',
    name: 'Roads',
    imgPath: '/assets/background_samples/map_roads.png',
  },
  [MapboxStyle.Water]: {
    url: 'mapbox://styles/cartapuce/ck8ynyj0x022h1hpmffi87im9',
    name: 'Water',
    imgPath: '/assets/background_samples/map_water.png',
  },
  [MapboxStyle.Administrative]: {
    url: 'mapbox://styles/cartapuce/ck8vkvxjt27z71ila3b3jecka',
    name: 'Borders',
    imgPath: '/assets/background_samples/map_borders.png',
  },
  satellite: { url: 'mapbox://styles/mapbox/satellite-v9', name: '' },
  regular: { url: 'mapbox://styles/mapbox/streets-v11', name: '' },
}

export const DEFAULT_TRANSFORMS = {
  [ROAD_SIMPLE_WHITE]: { color: ROAD_WHITE, isEditable: true, name: 'roads' },
  [WATER_CYAN]: { color: null, isEditable: true, name: 'water' },
}

export const DEFAULT_MAP_TITLE = 'Maposaic'
