import React from 'react'
import { Divider } from 'antd'
import Title from 'antd/lib/typography/Title'

import './style.less'

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions">
      <Title level={2}>
        Terms and Conditions for <a href="/">Maposaic</a>
      </Title>
      <Divider />
      <div>
        <p>Last updated: Fabruary 1st, 2024</p>
        <p>Please read these terms and conditions carefully before using Maposaic.</p>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The
          following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with
              a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing authority.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: France
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
              refers to Vicobiz, 112 bd Raspail, 75006 Paris, France. Registration number: 948 952 783 R.C.S Paris.
            </p>
          </li>
          <li>
            <p>
              <strong>Maposaic</strong> (referred also as <strong>Service</strong>) refers to the website accessible
              from{' '}
              <a href="https://maposaic.com" rel="external noreferrer nofollow noopener" target="_blank">
                https://maposaic.com
              </a>{' '}
              which is a product of Vicobiz.
            </p>
          </li>
          <li>
            <p>
              <strong>Products</strong> refer to the posters that are individualized by the User and sold by the
              Service. These Products may include a frame.
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a
              digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form
              the entire agreement between You and the Company regarding the use of the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party Social Media Service</strong> means any services or content (including data,
              information, products or services) provided by a third-party that may be displayed, included or made
              available by the Service.
            </p>
          </li>

          <li>
            <p>
              <strong>You</strong> (also referred as <strong>The User</strong> or <strong>The Customer</strong>) means
              the individual accessing or using the Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h1>Payment and fees</h1>
        <p>
          When you order a Product you will be charged then current fees, which we may change from time to time (such
          when we have holiday sales or offer you a discount of base product price). We may choose to temporarily change
          the fees for our Products.
        </p>
        <p>
          By placing an order through the Site, you are confirming that you are legally entitled to use the means of
          payment tendered and, in the case of card payments, that you are either the cardholder or have the
          cardholder’s express permission to utilize the card to effect payment.
        </p>
        <p>
          We may refuse to process a transaction for any reason or refuse Service to anyone at any time at our sole
          discretion. We will not be liable to you or any third party by reason of refusing or suspending any
          transaction after processing has begun.
        </p>
        <p>
          The User is responsible for paying all fees, payments and applicable taxes associated with our Site and
          Products. After receiving your order you will receive an e-mail from us confirming the details, description,
          and price for the Products
        </p>
        <p>
          Once a Product is bought, it cannot be refund, unless the Service is not able to produce it and ship it to the
          delivery address indicated in the process.
        </p>
        <p>The Service is not responsible for any delay in the delivery of the Product, or for any damage or loss.</p>
        <p>Payment of the total price plus delivery must be made in full before dispatch of your Products.</p>
        <h1>Shipping, returns, and right of withdrawal</h1>
        <p>
          Once you have clicked on the “confirm” button, it is not possible to edit or cancel your order. If you want to
          change some parameters, Customer addresses, etc., please contact us as soon as possible. We are not bound to
          make such modifications in your order, but we will do our best on a case-by-case basis. Replacement of
          Products and credits to the Member’s account for Products claimed as damaged or not received are subject to
          Maposaic investigation and discretion.
        </p>
        <p>
          Maposaic will review the replacement of Products or refund requests within 14 days of placed order. If a
          Product is missing, damaged or subject to print error, Maposaic will replace the erroneous Product with a new
          Product. To issue a refund process based on the aforementioned reasons, contact compass@maposaic.com. Refund
          requests will be processed within five (10) working days. For the sake of clarity, Maposaic is not responsible
          for situations when the Customer has provided incorrect information, such as wrong Customer name or address.
          In such cases, an extra payment will be applied.
        </p>
        <p>
          Due to the individualization of the Product there is no right of withdrawal for the Product after placed
          order.
        </p>
        <h1>Description of Products</h1>
        <p>
          Whilst many component parts of our Products are standard, all Products available for purchase are described on
          their specific design page on our Site. We always try to represent each design as accurately as possible.
        </p>
        <p>
          We use our best efforts to provide you with the best images and descriptions, but unfortunately cannot
          guarantee that colors and details in website images are 100% accurate representations of the Product.
        </p>
        <h1>Purchase of Products</h1>
        <p>
          Your order represents an offer to us to purchase a Product which is accepted by us once we have sent you an
          e-mail order confirmation. Any Product on the same order which we have not confirmed in an order confirmation
          e-mail does not form part of that contract.
        </p>
        <p>
          Maposaic shall under no circumstances be held liable for any special losses due to specific circumstances of
          the User and/or Customer, indirect or consequential losses or wasted expenditure.
        </p>
        <p>
          Orders are placed and received exclusively via the Site. Before ordering from us, it is the Customer’s
          responsibility to check and determine the full ability to receive the Products. Correct Customer’s address and
          postcode/zip code, User’s up-to-date telephone number and e-mail address are absolutely necessary to ensure
          successful delivery of Products.
        </p>
        <p>
          All information asked on the checkout page must be filled in precisely and accurately. Maposaic will not be
          responsible for missed delivery because of a wrong delivery address or an inappropriate phone number. Should
          you like to ask for a change in the delivery address, notify a change in a phonenumber or any other special
          requirements, please contact Maposaic.
        </p>
        <h1>Delivery</h1>
        <p>
          Maposaic deliver to Users/Customers to most places in the world. Customer is responsible for delivery prices.
          Delivery prices are additional to the Product’s price and may vary depending on delivery location and/or sort
          of Products, and additional charges may be added to the order for remote or difficult to access locations that
          require special attention. Standard delivery charges are shown on our checkout page; however, we reserve the
          right to advise you of any additional delivery charges that apply to your specific delivery address.
        </p>
        <p>
          Maposaic cannot guarantee delivery dates and accept no responsibility, apart from advising you of any known
          delay, for Products that are delivered after the estimated delivery date. Standard delivery times are shown on
          the Site. It is only an average estimation, and some delivery can take longer, or alternatively be delivered
          much faster. All delivery estimates given at the time of placing and confirming order are subject to change.
          We try our best to make delivery of Product as simple as possible. Ownership of the Products will only pass to
          Customers when we receive full payment of all sums due in respect of the Products, including delivery charges.
        </p>
        <h1>Release</h1>
        <p>
          You release us (and employees) from claims, demands and damages (actual and consequential) of every kind and
          nature, known and unknown, arising out of or in any way connected with such disputes. You agree that Maposaic
          has no control over and does not guarantee the delivery of the advertised collaborations and that Maposaic
          shall be released from any and all damages resulting from the failure to receive any benefits of an
          anticipated collaboration.
        </p>
        <h1>Personal data</h1>
        <p>
          The Customer accepts that Maposaic will store and use relevant personal data about the Customer in order to
          deliver the Product.
        </p>
        <p>
          The Customer may upload a map picture (bought or not) to the <a href="/gallery">public gallery</a>. The
          Customer agrees to let the ownership of this map picture to Maposaic. The map picture will be visible by all
          other Maposaic users.
        </p>
        <h1>Indemnity</h1>
        <p>
          You will indemnify and hold Maposaic (and its officers, directors, agents, subsidiaries, joint ventures and
          employees) harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party
          due to or arising out of your breach of this Agreement, or your violation of any law or the rights of a third
          party.
        </p>
        <p>
          If a dispute arises between you and Maposaic, we strongly encourage you to first contact us directly to seek a
          resolution. We will consider reasonable requests to resolve the dispute through alternative dispute resolution
          procedures, such as mediation or arbitration, as alternatives to litigation.
        </p>
        <p>
          Contracts for the purchase of Products through our Site and any dispute or claim arising out of or in
          connection with them or their subject matter or formation (including non-contractual disputes or claims) must
          be resolved by a court located in France.
        </p>
        <p>
          Any dispute or claim arising out of or in connection with Agreement or its formation (including
          non-contractual disputes or claims) will be subject to the non-exclusive jurisdiction of the courts of France.
        </p>
        <h1>Phone number</h1>
        <p>You allow your phone number usage for any needs relative to the delivery of the Product.</p>
        <h1>Acknowledgment</h1>
        <p>
          These are the Terms and Conditions governing the use of this Service and the agreement that operates between
          You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the
          use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
          Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with
          any part of these Terms and Conditions then You may not access the Service.
        </p>
        <p>
          You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
        </p>
        Customers outside of the European Union may be subjected to pay custom duties (as applicable within each
        jurisdiction). Maposaic will not be held responsible for any such duties.
        <h1>Links to Other Websites</h1>
        <p>
          Our Service may contain links to third-party web sites or services that are not owned or controlled by the
          Company.
        </p>
        <p>
          The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
          practices of any third party web sites or services. You further acknowledge and agree that the Company shall
          not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with the use of or reliance on any such content, goods or services available on or through
          any such web sites or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
          services that You visit.
        </p>
        <h1>Limitation of Liability</h1>
        <p>
          Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers
          under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the
          amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the
          Service.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
          for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for business interruption, for personal
          injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose.
        </p>
        <p>
          Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations may not apply. In these states, each
          party's liability will be limited to the greatest extent permitted by law.
        </p>
        <h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
        <p>
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf
          of its Affiliates and its and their respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all
          implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
          warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without
          limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of
          any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption, meet any performance
          or reliability standards or be error free or that any errors or defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation
          or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
          information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted
          or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided
          through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of
          the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful
          components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
          statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
          But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest
          extent enforceable under applicable law.
        </p>
        <h1>Severability and Waiver</h1>
        <h2>Severability</h2>
        <p>
          If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
          law and the remaining provisions will continue in full force and effect.
        </p>
        <h2>Waiver</h2>
        <p>
          Except as provided herein, the failure to exercise a right or to require performance of an obligation under
          these Terms shall not effect a party's ability to exercise such right or require such performance at any time
          thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
        </p>
        <h1>Translation Interpretation</h1>
        <p>
          These Terms and Conditions may have been translated if We have made them available to You on our Service. You
          agree that the original English text shall prevail in the case of a dispute.
        </p>
        <h1>Changes to These Terms and Conditions</h1>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
          material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at Our sole discretion.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions become effective, You agree to be bound by
          the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website
          and the Service.
        </p>
        <h1>Contact Us</h1>
        <p>If you have any questions about these Terms and Conditions, You can contact us.</p>
        <p>You may always reach out to us with questions about our services or regarding data protection.</p>
        <ul>
          <li>
            <p>
              By email: <a href="mailto:compass@maposaic.com">compass@maposaic.com</a>
            </p>
          </li>
          <li>
            <p>
              By visiting this page on our website:{' '}
              <a
                href="https://maposaic.com/terms-and-conditions"
                rel="external nofollow noopener noreferrer"
                target="_blank"
              >
                https://maposaic.com/terms-and-conditions
              </a>
            </p>
          </li>
        </ul>
      </div>
      <Divider />
      <h1>Attribution and copyright to work of others</h1>
      <ul>
        <li>
          <p>
            Some of our products are made possible through the work of the
            <a
              href="https://wiki.openstreetmap.org/wiki/Main_Page"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              {' '}
              OpenStreetMap
            </a>{' '}
            community and foundation
          </p>
        </li>
        <li>
          <p>
            Some of our Products are made possible through the work of{' '}
            <a href="https://mapbox.com" target="_blank" rel="external nofollow noopener noreferrer">
              Mapbox
            </a>
          </p>
        </li>
        <li>
          <p>
            <a href="https://www.freakflagsprite.com/" target="_blank" rel="external nofollow noopener noreferrer">
              Freak Flags
            </a>{' '}
            has been used to display country flags
          </p>
        </li>
      </ul>
    </div>
  )
}
export default TermsAndConditions
