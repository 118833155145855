import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const prodConfig = {
  apiKey: 'AIzaSyAuounsVwHYGk-hZ_0EBsLi0aQLhYepYJk',
  authDomain: 'maposaic-99785.firebaseapp.com',
  databaseURL: 'https://maposaic-99785.firebaseio.com',
  projectId: 'maposaic-99785',
  storageBucket: 'maposaic-99785.appspot.com',
  messagingSenderId: '702337108747',
  appId: '1:702337108747:web:488c6903c51e09917ff00e',
  measurementId: 'G-GCGS9CE9RL',
}
const devConfig = {
  apiKey: 'AIzaSyAgYOZlYs-tmFJzQOstgXFP2b37-vKAX1U',
  authDomain: 'maposaic-dev.firebaseapp.com',
  projectId: 'maposaic-dev',
  storageBucket: 'maposaic-dev.appspot.com',
  messagingSenderId: '806505887148',
  appId: '1:806505887148:web:928ca2bd4dd8d70be13609',
  measurementId: 'G-M7HC0BWZV8',
}
const firebaseConfig = process.env.REACT_APP_ENV_NAME === 'production' ? prodConfig : devConfig
const firebaseApp = initializeApp(firebaseConfig)

export const analytics = getAnalytics(firebaseApp)
export const db = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
